@use 'colors';
@use 'media';

.accordion {
  margin: 40px;

  @include media.down('mobile') {
    margin: 0;
  }
}

.title {
  text-align: center;
  margin: 60px 40px;
  font-size: 45px;
  line-height: 50px;
}

.accordionItem,
.accordionHeader {
  border: none !important;

  @include media.down('mobile') {
    padding: 0;
  }
}

.accordionItem {
  margin-bottom: 50px;
}

.accordionButton {
  background-color: transparent;
  border: none !important;
  padding: 0;
  color: colors.$black;
  text-align: left;
  width: 100%;
  box-shadow: none !important;
  margin-bottom: 25px;
  display: flex;

  p {
    width: 90%;
    margin-left: 20px;
  }

  @include media.up('tablet') {
    font-size: 30px;
    line-height: 38px;
  }
  .accordionCarat {
    min-width: 48px;
    transform: scale(0.7);

    @include media.down('mobile') {
      transform: scale(0.5);
    }
  }
  .accordionCaratHide {
    min-width: 48px;
    transform: scale(0.7) rotate(180deg);

    @include media.down('mobile') {
      transform: scale(0.5) rotate(180deg);
    }
  }
}

.accordionBody {
  padding-left: 60px;
  p:last-child {
    margin-bottom: 0;
  }

  @include media.down('mobile') {
    padding: 0 40px;
  }
}
