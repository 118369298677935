@use 'colors';
@use 'media';
@use 'gradients';

.introSection {
  display: flex;

  @include media.down('tablet') {
    flex-direction: column;
  }
}

.left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  background-color: colors.$service-blue;
  color: colors.$white;
  width: 40%;
  padding: 62px;

  @include media.down('tablet') {
    width: 100%;
  }

  .text {
    width: 100%;
    padding: 15%;

    @include media.down('tablet') {
      width: 100%;
    }
  }
}

.right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 50px 170px;
  width: 60%;

  @include media.down('tablet') {
    width: 100%;
    padding: 40px;
  }

  .listHeader {
    color: colors.$blue;
    font-size: 30px;

    @include media.down('tablet') {
      text-align: center;
    }
  }

  .text {
    display: flex;
    flex-direction: column;
    max-width: 610px;
    font-size: 15px;

    @include media.down('tablet') {
      margin: 0 auto;
      width: 100%;
    }

    p {
      box-shadow: -4px 2px 14px -2px rgb(0 0 0 / 25%);
      padding: 30px;

      &.disclaimer {
        box-shadow: none;
        padding: 0;
      }
    }

    button {
      width: 375px;
      height: 100px;
      margin: 0 auto;
      font-size: 25px;

      @include media.down('tablet') {
        width: 100%;
      }
    }
  }
}

.howItWorks {
  text-align: center;
  margin: 75px auto !important;

  .iconSection {
    display: flex;
    margin: 0 auto;

    @include media.down('tablet') {
      flex-direction: column;
      max-width: 540px;
      width: 80%;
    }

    .iconWithText {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      padding: 0 12px;
      width: 33%;

      @include media.down('tablet') {
        width: 100%;
        margin-bottom: 48px;
      }

      .link {
        color: colors.$black;
        text-decoration: underline;
      }
    }
  }
}

.memberStories {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 56px;
  overflow: hidden;
  background: gradients.$dark-blue;

  @include media.down('mobile') {
    padding: 40px 20px;
  }

  .headerText {
    color: colors.$white;
    text-align: center;
    letter-spacing: 3px;
  }

  .carousel {
    position: relative;
    display: flex;
    align-items: center;
    min-height: 550px;

    @include media.down('desktop') {
      min-height: 700px;
    }

    @include media.down('tablet') {
      min-height: 800px;
    }

    @include media.down('mobile') {
      min-height: 550px;
    }

    .slide {
      display: flex;
      align-items: center;
      width: 80%;
      height: 100%;
      margin: 0 auto;
      transition-duration: 0.8s;

      @include media.down('tablet') {
        width: 100%;
      }

      @include media.down('mobile') {
        flex-direction: column;
      }

      &.current {
        left: 0;
        opacity: 1;
        visibility: visible;
      }

      &.previous {
        position: absolute;
        visibility: visible;
        opacity: 0;
        left: -200vw;
      }

      &.next {
        position: absolute;
        visibility: visible;
        opacity: 0;
        left: -400vw;
      }

      .image {
        min-width: 40%;
        max-width: 450px;

        @include media.down('tablet') {
          height: 450px;
          object-fit: cover;
          object-position: 50% 0;
        }

        @include media.down('mobile') {
          height: auto;
          width: 100%;
        }
      }

      .text {
        width: 60%;
        background: colors.$white;
        padding: 30px 50px;

        @include media.down('tablet') {
          width: 100%;
          padding: 30px;
        }

        p {
          text-align: center;
          margin: 0 20px;
          line-height: 28px;
        }

        svg {
          transform: scale(1.5);
          margin-bottom: 10px;
        }

        .iconReverse {
          float: right;
          transform: scale(1.5) scaleX(-1) scaleY(-1);
          margin-top: 10px;
        }

        .savingsText {
          color: colors.$service-blue;
        }
      }
    }
  }

  button {
    margin: 0 auto;
  }
}

.sectionEstatePlanning {
  padding: 50px 0;
  text-align: center;
  width: 75%;
  max-width: 1320px;
  margin: 0 auto;

  @include media.down('desktop') {
    width: 90%;
    max-width: 960px;
  }

  @include media.down('tablet') {
    width: 100%;
    max-width: 720px;
  }
}

.subHeading {
  color: colors.$blue;
}

.box {
  border: 5px solid;
  border-image: gradients.$green-to-blue-linear;
  border-image-slice: 1;
  margin: 45px 70px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  color: colors.$blue;
  padding: 32px 40px;

  @include media.down('tablet') {
    margin: 45px 0;
  }

  @include media.down('mobile') {
    flex-direction: column;
  }

  p {
    width: 48%;
    font-size: 22px;
    line-height: 36px;
    text-align: left;

    @include media.down('mobile') {
      text-align: center;
      width: 100%;
    }

    &.largeFont {
      font-size: 100px !important;
      line-height: 105px;
      font-weight: 700;
      text-align: center;

      @include media.down('mobile') {
        margin-bottom: 30px;
      }
    }
  }
}
.calculatorButton {
  width: 100%;
  max-width: 400px;
  padding-left: 12px;
  padding-right: 12px;

  @include media.down('desktop') {
    p {
      font-size: 26px;
    }
  }
  @include media.down('tablet') {
    p {
      font-size: 20px;
    }
  }
  @include media.down('mobile') {
    p {
      font-size: 17px;
    }
  }
}

.info {
  background-color: colors.$service-blue;
  text-align: center;
  padding: 50px 0;
  color: colors.$white;

  p {
    width: 80%;
    margin: 0 auto;
  }
}

.wills {
  display: flex;
  background-color: colors.$service-green;
  padding: 48px 50px;

  @include media.down('mobile') {
    flex-direction: column;
  }
}

.gradientBox {
  color: colors.$white;
  min-height: 300px;
  padding: 32px;
  width: 30%;
  margin: 0 2%;
  text-align: center;
  background: gradients.$green-to-blue-one-swoop;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @include media.down('mobile') {
    width: 100%;
    margin: 15px 0;
  }
}

.videoSection {
  padding: 80px !important;
  text-align: center;

  @include media.down('mobile') {
    padding: 20px;
  }

  .videoWrapper {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    margin: 40px 0 30px;
    overflow: hidden;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 1px solid;
    }
  }
}

.cost {
  text-align: center;
  padding: 80px 0;
  width: 75%;

  .costComparison {
    display: flex;
    justify-content: center;
    align-items: center;
    color: colors.$blue;

    @include media.down('mobile') {
      flex-direction: column;
      justify-content: space-between;
    }

    img {
      height: 87px;

      @include media.down('mobile') {
        display: none;
      }
    }

    p {
      font-size: 96px;
      text-align: center;
      margin: 20px 30px;

      @include media.down('tablet') {
        font-size: 60px;
      }

      &.vs {
        font-size: 60px;
        padding: 20px 0;
      }

      span {
        font-size: 32px;
      }

      sup {
        font-size: 20px;
      }
    }
  }
}

.costTableSection {
  display: flex;
  font-weight: 600;

  .image {
    width: 40%;
    min-height: 100%;
    background: url('/couple-comparing-costs.jpg');
    background-position: 50%;
    background-size: cover;

    @include media.down('tablet') {
      display: none;
    }
  }

  p {
    width: 95%;

    @include media.down('tablet') {
      width: 100%;
    }
  }

  .tableSection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    color: colors.$white;
    background: colors.$service-blue;
    width: 60%;
    padding: 60px;

    @include media.down('tablet') {
      width: 100%;
      margin: 0 auto;
    }

    @include media.down('mobile') {
      padding: 60px 0;
    }

    .tableSectionHeader {
      padding: 0 20px;
    }

    table {
      width: 95%;

      @include media.down('tablet') {
        width: 100%;
      }

      td {
        padding: 16px 20px;

        @include media.down('mobile') {
          font-size: 14px;
          padding: 16px 0 16px 8px;
        }
      }

      thead {
        p {
          font-size: 22px;

          @include media.down('mobile') {
            font-size: 18px;
          }
        }
      }

      tfoot {
        color: colors.$black;
        background: colors.$service-green;

        td {
          padding: 24px 20px;
          vertical-align: middle;
        }

        p {
          font-size: 22px;
        }
      }

      .lightRow {
        background: colors.$blue;
      }
    }
  }
}

.calculate {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background: colors.$service-green;
  padding: 70px;

  @include media.down('mobile') {
    flex-direction: column;
    padding: 40px;

    .title {
      font-size: 45px;
      line-height: 50px;
      text-align: center;
    }
  }

  .linkButton {
    background: colors.$service-blue;
    color: colors.$white;
    padding: 20px;
    text-align: center;

    @include media.down('mobile') {
      padding: 16px;
      margin-top: 32px;
    }
  }
}

.footnotes {
  display: flex;
  justify-content: center;
  margin: 0 auto 56px !important;

  ol {
    list-style-type: decimal;
    font-size: 12px;
  }
}

.getStarted {
  display: flex;
  justify-content: space-around;
  background: gradients.$blue-green-one-swoop;
  padding: 70px;
  color: colors.$white;

  @include media.down('mobile') {
    flex-direction: column;
    padding: 40px;

    p {
      font-size: 45px;
      line-height: 50px;
      text-align: center;
    }

    button {
      padding: 16px;
      margin-top: 32px;
    }
  }
}
